import { useState } from 'react'
import {
  Typography,
  Stack,
  Input,
  FormControl,
  Box,
  PaletteColorOptions,
} from '@mui/material'
import numeral from 'numeral'
import inputStyles from './InputField.styles'
import { InputFieldProps } from './InputField.types'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InputLabel from './InputLabel'
import { theme } from 'styles-new/mui5-transition/theme'

function InputField({
  dataCy,
  label,
  hint,
  value,
  handleChange,
  addOn,
  addOnPosition = 'start',
  format = '0,0',
  error = false,
  errorMessage,
  disabled = false,
  inputMode = 'text',
  extraStyles = {},
  addOnExtraStyles = {},
  clickAway, // onBlur
  clickIn, // onFocus
  hideExtraText = false,
  enableRangeDash,
  removeBorderOnFocus = false,
  ...extraProps
}: InputFieldProps) {
  const styles = inputStyles(addOn, addOnPosition, disabled, extraStyles)
  const [parentBorderOnFocus, setParentBorderOnFocus] = useState('')

  const getValue = (value: string | number) => {
    if (inputMode === 'numeric') {
      if (!value) return ''
      return numeral(value).format(format)
    }
    return value
  }

  const onChange = (e: { target: any }) => {
    const { value } = e.target
    if (inputMode === 'numeric') {
      if (!value) return handleChange('')
      return handleChange(numeral(value).format('0'))
    }
    handleChange(value)
  }
  const handleBlur = () => {
    setParentBorderOnFocus('')
    clickAway?.()
  }

  const handleFocus = () => {
    if (removeBorderOnFocus) return
    setParentBorderOnFocus(
      `2px solid ${theme.palette.primary[500 as keyof PaletteColorOptions]} !important`
    )
    clickIn?.()
  }

  return (
    <Stack width={1} spacing={1}>
      {label && (
        <Typography
          sx={hideExtraText ? { visibility: 'hidden' } : {}}
          variant="subtitle2"
        >
          {label}
        </Typography>
      )}
      {hint && (
        <Typography sx={hideExtraText ? { visibility: 'hidden' } : {}} variant="body2">
          {hint}
        </Typography>
      )}
      <FormControl component={Stack} error={error}>
        <Stack
          position="relative"
          direction={addOnPosition === 'start' ? 'row' : 'row-reverse'}
        >
          {addOn && (
            <InputLabel
              addOnPosition={addOnPosition}
              addOn={addOn}
              extraStyles={addOnExtraStyles}
            />
          )}
          <Input
            onBlur={handleBlur}
            onFocus={handleFocus}
            fullWidth={true}
            sx={{ ...styles, border: parentBorderOnFocus }}
            disableUnderline
            value={getValue(value)}
            inputMode={inputMode}
            onChange={onChange}
            error={error}
            disabled={disabled}
            inputProps={{
              'data-cy': dataCy,
            }}
            {...extraProps}
          />
          {enableRangeDash && (
            <Box
              sx={{
                width: '8px',
                height: 0,
                border: '1px solid',
                borderColor: 'grey.600',
                position: 'absolute',
                right: -16,
                top: '50%',
              }}
            ></Box>
          )}
        </Stack>
        {errorMessage && (
          <Stack
            sx={{ color: 'error.700', visibility: hideExtraText ? 'hidden' : 'visible' }}
            mt={1}
            gap={1}
            direction="row"
            justifyContent="start"
            alignItems="center"
          >
            <ErrorOutlineIcon fontSize="large" />
            <Typography
              sx={hideExtraText ? { visibility: 'hidden' } : {}}
              color="inherit"
              variant="body2"
            >
              {errorMessage}
            </Typography>
          </Stack>
        )}
      </FormControl>
    </Stack>
  )
}

export default InputField
